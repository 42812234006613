import http from '@/utils/http';

/**
 * 系统菜单
 */
export default {
  getList: {
    p: 'get,/system/menu/getlist',
    r: () => http({ url: '/system/menu/getlist', method: 'get' })
  },
  // getOne: {
  //   p: 'post,/system/menu/add',
  //   r: (data) => http({ url: '/system/menu/add', method: 'post', data })
  // },
  add: {
    p: 'post,/system/menu/add',
    r: (data) => http({ url: '/system/menu/add', method: 'post', data })
  },
  update: {
    p: 'post,/system/menu/update',
    r: (data) => http({ url: '/system/menu/update', method: 'post', data })
  },
  del: {
    p: 'post,/system/menu/delete',
    r: (data) => http({ url: '/system/menu/delete', method: 'post', data })
  },
  getTree: {
    p: 'get,/system/menu/handle_tree',
    r: (data) => http({ url: '/system/menu/handle_tree', method: 'get', data })
  }
  // getOne(data) {
  //   return http({
  //     url: 'system/menu/delete',
  //     method: 'post',
  //     data
  //   });
  // },
  // add(data) {
  //   return http({
  //     url: 'system/menu/add',
  //     method: 'post',
  //     data
  //   });
  // },
  // update(data) {
  //   return http({
  //     url: 'system/menu/update',
  //     method: 'post',
  //     data
  //   });
  // },
  // del(data) {
  //   return http({
  //     url: 'system/menu/delete',
  //     method: 'post',
  //     data
  //   });
  // },
  // getTree(data) {
  //   return http({
  //     url: 'system/menu/handle_tree',
  //     method: 'get',
  //     data
  //   });
  // }
};
