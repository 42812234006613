import http from '@/utils/http';

/**
 * 公共接口
 */
export default {
  // 唯一性校验  check/unique
  // type 类型  user
  // field 要校验的字段名
  // value 要校验的数据
  checkUnique (data) {
    return http({
      url: '/check/unique',
      method: 'post',
      data
    });
  },
  getList (data) {
    return http({
      url: '/system/user/list',
      method: 'post',
      data
    });
  },
  test (data) {
    return http({
      url: '/system/export/user',
      method: 'post',
      responseType: 'blob',
      data
    });
  },
  testindex (data) {
    return http({
      url: '/system/export/index',
      method: 'post',
      responseType: 'blob',
      data
    });
  },
  testAPI (data) {
    return http({
      url: data.path,
      method: data.method,
      responseType: 'blob',
      config: { responseType: 'arraybuffer' }
    });
  }
};
