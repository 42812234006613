import './list.scss';
import dataApi from '@/api/system/role.js';
import menuApi from '@/api/system/menu.js';

export default {
  name: 'project-list',
  components: {},
  data () {
    return {
      page: {
        pageSize: 10,
        pageSizes: [3, 10, 20, 30, 40, 50, 100]
      },
      searchData: {},
      tableData: [],
      tbleOption: {
        align: 'center',
        cellBtn: false,
        // ...this.$config.table,
        ...this.$store.getters.tableConfig,
        column: [
          {
            // 角色ID   role_id
            label: '角色ID',
            addDisplay: false,
            editDisplay: false,
            hide: true,
            prop: 'role_id'
          },
          {
            // 角色名称   role_name
            label: '角色名称',
            rules: [
              {
                required: true,
                message: '请输入角色名称',
                trigger: 'blur'
              }
            ],
            prop: 'role_name'
          },
          {
            // 权限符   role_key
            label: '权限符',
            rules: [
              {
                required: true,
                message: '请输入权限符',
                trigger: 'blur'
              }
            ],
            prop: 'role_key',
            value: 'system'
          },
          {
            // 菜单权限  menu_check_strictly
            label: '菜单权限',
            hide: true,
            prop: 'menu_check_strictly',
            type: 'tree',
            multiple: true,
            addDisplay: true,
            editDisplay: true,
            rules: [
              {
                required: true,
                message: '请选择菜单权限',
                trigger: 'blur'
              }
            ],
            dicData: [],
            span: 24,
            filterText: '搜索关键字制自定义',
            leafOnly: false,
            checkStrictly: true
            // display: false
          },
          // role_sort
          { // 排序
            type: 'number',
            value: 0,
            label: '排序',
            // rules: [{
            //   required: true,
            //   message: '请选择上级菜单',
            //   trigger: 'blur'
            // }],
            span: 12,
            prop: 'role_sort'
          },

          // {
          //   // 部门权限  dept_check_strictly
          //   label: '部门权限',
          //   prop: 'dept_check_strictly',
          //   rules: [
          //     {
          //       required: true,
          //       message: '请选择部门权限',
          //       trigger: 'blur'
          //     }
          //   ],
          //   type: 'select',
          //   dicData: []
          // },
          {
            //  数据范围（1：全部数据权限 2：自定数据权限 3：本部门数据权限 4：本部门及以下数据权限）  data_scope
            label: '数据范围',
            row: true,
            // span: 12,
            type: 'select',
            value: '1',
            dicData: [
              {
                label: '全部数据权限',
                value: '1'
              },
              {
                label: '自定数据权限',
                value: '2'
              },
              {
                label: '本部门数据权限',
                value: '3'
              },
              {
                label: '本部门及以下数据权限',
                value: '4'
              }
            ],
            rules: [
              {
                required: true,
                message: '请选择数据范围',
                trigger: 'blur'
              }
            ],
            prop: 'data_scope'
          },
          {
            // 状态（0正常 1停用）
            label: '状态',
            row: true,
            // span: 12,
            type: 'radio',
            value: 0,
            dicData: [
              {
                label: '正常',
                value: 1
              },
              {
                label: '停用',
                value: 0
              }
            ],
            rules: [
              {
                required: true,
                message: '请选择角色状态',
                trigger: 'blur'
              }
            ],
            prop: 'status'
          },
          {
            // 创建者 create_by
            label: '创建者',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_user'
          },
          {
            // 创建时间 create_time
            label: '创建时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          },
          {
            // 更新者 update_by
            label: '更新者',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_user'
          },
          {
            // 更新时间 update_time
            label: '更新时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_time'
          },
          {
            // 备注 remark
            label: '备注',
            prop: 'remark',
            span: 24,
            type: 'textarea'
          }
        ]
      }
    };
  },
  computed: {},
  created () {
    console.log('id', this.$route.params.id);
  },
  watch: {
    menu_check_strictly: {
      handler () {
        this.menu_check_strictly = this.$refs.tree.getHalfList();
      }
    }
  },
  mounted () {
  },
  filters: {},
  methods: {
    // 返回
    goBack () {
      console.log('go back');
      this.$router.go(-1);
    },
    getTree () {
      // menu_check_strictly
      const menu_check_strictly = this.findObject(this.tbleOption.column, 'menu_check_strictly');
      // const dept_check_strictly = this.findObject(this.tbleOption.column, 'dept_check_strictly');
      menuApi.getList.r().then((res) => {
        menu_check_strictly.dicData = res.data;
        menu_check_strictly.props = {
          label: 'menu_name',
          value: 'menu_id'
        };
      });
      // menu.getList().then((res) => {
      //   console.log('12312312!@#!@#', res);
      // });
    },
    // 分页
    onLoad (page) {
      this.page = page;
      this.getList();
      this.getTree();
    },
    // 筛选
    searchChange (params, done) {
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset () {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    // 添加
    handleSubmit (form, done, loading) {
      const postData = { ...form, dict_type: this.$route.params.id };
      dataApi.add.r(postData).then(() => {
        done(form);
        this.getList();
        loading();
      });
      // done(form);
    },
    // 删除
    rowDel (form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('删除数据', form);
        dataApi.del.r({ role_id: form.role_id }).then(res => {
          console.log(res);
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    // 修改
    rowUpdate (form, index, done, loading) {
      console.log('index', form, index);
      dataApi.update.r(form).then(res => {
        console.log(res);
        done(form);
        this.getList();
        loading();
      });
      // done(form);
    },
    // 获取
    getList () {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then(res => {
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    }
  }
};
